.header {
    border-bottom: 1px solid #d8d8d8; 

    .container--wide {
        max-width: 1840px;
    }
    
    .header-content {
        height: 100px;
        
        @include media-breakpoint-down(md) {
            height: auto;
        }
    }

    input::placeholder {		
        font-size: 15px;	
    }

    .search-container {
        display: flex;
    }

    .search-input {
        width: 242px;
        height: 49px;
        padding-left: 2.5em;
        padding-top: 16px;
        padding-bottom: 14px;
        border-radius: 3px;
        border: 1px solid #e5e5e5;
        color: #444;
    }

    .nav-selected {
        color: $color-primary;
        background-color: transparent;
        border-right: none;
    }

    #header_searchfield {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .svg-icon {
        position: absolute;
        left: 1em;
        width: 1em;
        height: 1em;
    }

}

.logo-image {
    width: 12rem;
    height: auto;

    @include media-breakpoint-down(md) {
        display: none;
    }
    
}