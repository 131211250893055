$padding: .5rem;

section.blockarea {
    height: auto;
    margin: 0;

    // img.hero-img {
    //     position: relative;
    //     width: 100%;
    //     object-fit: cover;
    //     height: 40vh;
    //     max-height: 440px;
    //     object-position: 50%;

    //     @include media-breakpoint-up(md) {
    //         max-height: 660px;
    //         height: auto;
    //     }
    // }

    .hero {
        position: relative;
        min-height: 320px;
        width: 100%;
        background-size: cover;
        background-position: center center;
                
        @include media-breakpoint-up(md) {
            height: 300px;
        }
        @include media-breakpoint-up(lg) {
            height: 450px;
        }
        @include media-breakpoint-up(xl) {
            height: 580px;
        }


        &-text {
            position: absolute;
            // top: 35%;
            top: 50%;
            left: 0%;
            width: 67%;
            padding: 1.5rem;
            
            @include media-breakpoint-up(sm) {
                top: 40%;
                left: 7%;
                width: 64%;
            }

            @include media-breakpoint-up(md) {
                top: 50%;
            }

            @media only screen and (min-width: 1400px) {
                width: 45%;
                left: 25%;
            }
        }

        &-title {
            font-size: 1.5rem;
            line-height: 1.3;

            @include media-breakpoint-up(sm) {
                font-size: 1.7rem;
            }

            @include media-breakpoint-up(lg) {
                font-size: 3rem;
            }
        }

        &-highlight {
            
            /*
            display: inline;
            background: $color-primary;
            color: white;
            line-height: 2.7rem;
            padding-left: 8px;
            padding-right: 8px;
            -webkit-box-decoration-break: clone;
            -ms-box-decoration-break: clone;
            -o-box-decoration-break: clone;
            box-decoration-break: clone;
            opacity: 0.9;
            */

            display: inline;
            // background: rgba(255, 255, 255, 0.85);
            /* color: #4a4a4a; */
            line-height: 1.7rem;
            padding-left: 8px;
            padding-right: 8px;
            -webkit-box-decoration-break: clone;
            -ms-box-decoration-break: clone;
            -o-box-decoration-break: clone;
            box-decoration-break: clone;
            opacity: 1;
            color: #FFFFFF;
            text-shadow: 3px 2px 13px rgba(0, 0, 0, 0.25);

            @include media-breakpoint-up(lg) {
                // line-height: 5.3rem;
                line-height: 1.3em;
                padding: .5rem;
                padding-left: 25px;
                padding-right: 25px;
    
            }
        }
    }

    .campaign-hero {
        position: relative;
        width: 100%;
        min-height: 320px;
        background-color: #D13237;
        
        .hero-img {
            background-size: contain;
            margin: 0 auto;
            height: 500px;
            width: 350px;
            background-repeat: no-repeat;
            display: none;
            @include media-breakpoint-up(md) {
                height: 500px;
                width: 750px;
                display: block;
            }            
            @include media-breakpoint-up(lg) {
                height: 500px;
                width: 1600px;
                display: block;
            }
        }

        .hero-img-mobile {
            background-size: contain;
            margin: 0 auto;
            height: 800px;
            width: 375px;
            background-repeat: no-repeat;            
            display: block;
            @include media-breakpoint-up(md) {
                display: none;
            }             
        }

        .prod-img {
            background-size: auto 5%;
            background-position: 50% 25%;
            background-repeat: no-repeat;
            background-color: #FFFFFF;
            height: 800px;
            @include media-breakpoint-up(md) {
                height: 500px;
                background-size: auto 20%;
                background-position: center center;
            }
        }
    }
}

