// section.blockarea {
//     height: auto;
//     margin: 0;


    div.product-image-hero-wrapper {
        position: relative;
        width: 100%;
            
        div.color-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0.85;

            &--transparent {
                background-color: rgba(0, 0, 0, 0.25);
            }

            &--fullopaque {
                background-color: #D13138;
                opacity: 1;

                .svg-block {
                    opacity: 1;
                }
            }

            &--green {
                background: radial-gradient(circle, rgb(9, 170, 31) 0%, rgb(8, 160, 3) 100%);
            }
            
        }

        img.product-hero-img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }

        .prod-hero {
            height: 400px;
            width: 100%;
            background-size: cover;
            position: relative;
        }

        .prod-logo {
            // width: 50%;
            width: 70%;
            margin-bottom: 1em;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

    }


    div.product-hero-text {
        padding: 5rem 0;
        text-shadow: 3px 2px 13px rgba(0, 0, 0, 0.25);
        

        p {
            // text-align: center;
            text-align: left;
            color: #fff;
            font-size: 1.25rem;
            z-index: 1;
            position: relative;

            &.logo-text {
                font-size: 1.5rem;
            }
        }

        img {
            z-index: 1;
            position: relative;
        }

        h1.title {
            font-size: 2.333rem;
            text-align: center;
            margin: 1rem 0;
            color: #FFFFFF;
            z-index: 1;
            position: relative;

            @include media-breakpoint-down(md) {
                font-size: 1.6rem;
            }
        }
    }
// }

