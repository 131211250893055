@charset "UTF-8";
/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #191919;
  text-align: left;
  background-color: white; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 600; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0091d2;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: currentColor;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }
  @media (min-width: 1300px) {
    .container {
      max-width: 1300px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1300px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: white; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8e0f2; }

.table-hover .table-primary:hover {
  background-color: #a2d7ee; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a2d7ee; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dbdbdb; }

.table-hover .table-secondary:hover {
  background-color: #cecece; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #cecece; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8e2d2; }

.table-hover .table-success:hover {
  background-color: #a6dbc7; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a6dbc7; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #f8fbfc; }

.table-hover .table-info:hover {
  background-color: #e6f1f4; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #e6f1f4; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff0cb; }

.table-hover .table-warning:hover {
  background-color: #ffe9b2; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe9b2; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f6b8cd; }

.table-hover .table-danger:hover {
  background-color: #f3a1bd; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f3a1bd; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe; }

.table-hover .table-light:hover {
  background-color: #f1f1f1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f1f1f1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c6c6; }

.table-hover .table-dark:hover {
  background-color: #b9b9b9; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9b9b9; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: white;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: white;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1299.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1300px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1300px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1300px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1300px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 600 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #0091d2 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #006e9f !important; }

.text-secondary {
  color: #7f7f7f !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #666666 !important; }

.text-success {
  color: #00965e !important; }

a.text-success:hover, a.text-success:focus {
  color: #00633e !important; }

.text-info {
  color: #e5f1f4 !important; }

a.text-info:hover, a.text-info:focus {
  color: #c1dee5 !important; }

.text-warning {
  color: #ffc845 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ffb912 !important; }

.text-danger {
  color: #e0004d !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ad003b !important; }

.text-light {
  color: #fcfcfc !important; }

a.text-light:hover, a.text-light:focus {
  color: #e3e3e3 !important; }

.text-dark {
  color: #333333 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1a1a1a !important; }

.text-body {
  color: #191919 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.bg-white {
  background-color: #fff; }

.bg-dark {
  background-color: #4a4a4a; }

.bg-gray {
  background-color: #e6e6e6; }

.bg-darkgray {
  background-color: #7a7a7a; }

.bg-lightgray {
  background-color: #F4F4F4; }

.bg-theme-gray-0 {
  background-color: #f8f8f8; }

.block {
  background-color: transparent;
  background-clip: content-box; }
  .block.bg-white {
    background-color: #fff; }
  .block.bg-dark {
    background-color: #4a4a4a; }
  .block.bg-gray {
    background-color: #e6e6e6; }
  .block.bg-darkgray {
    background-color: #7a7a7a; }
  .block.bg-lightgray {
    background-color: #F4F4F4; }
  .block.bg-theme-gray-0 {
    background-color: #f8f8f8; }

.svg-icon {
  width: 2em;
  height: 2em;
  flex: 0 0 auto;
  transition: fill .25s ease-in-out; }
  .svg-icon--gray {
    fill: #A4A4A4;
    fill-rule: evenodd; }
  .svg-icon--white {
    fill: #fff;
    fill-rule: evenodd; }
    a:hover .svg-icon--white {
      fill: #e6e6e6; }
  .svg-icon--black {
    fill: #000;
    fill-rule: evenodd; }
    a:hover .svg-icon--black {
      fill: black; }

/* source-sans-pro-regular - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url("../fonts/source-sans-pro-v11-latin-regular.woff2") format("woff2"), url("../fonts/source-sans-pro-v11-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* source-sans-pro-600 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"), url("../fonts/source-sans-pro-v11-latin-600.woff2") format("woff2"), url("../fonts/source-sans-pro-v11-latin-600.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* Needed because bootstrap reboot.scss breaks concrete edit styling if box-sizing: border-box */
*,
*::before,
*::after {
  box-sizing: content-box; }

html,
body,
.container,
.container * {
  box-sizing: border-box; }

html {
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1); }
  @media (min-width: 1200px) {
    html {
      font-size: 17px; } }

div#ccm-theme-wrapper {
  width: 100% !important; }

@media (min-width: 1300px) {
  .container {
    max-width: 1300px; } }

body {
  font-size: 1rem;
  font-family: "Source Sans Pro", Sans-Serif;
  font-weight: normal;
  line-height: 1.5;
  color: #777777;
  margin: 0;
  background-color: #fff;
  text-align: left;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body button {
    border-radius: 0;
    font-size: 1.2rem;
    padding: 0.8rem 2.5rem;
    font-weight: 400; }

.table {
  border: none; }

.overflow-hidden {
  overflow: hidden; }
  @media (max-width: 991.98px) {
    .overflow-hidden-md-down {
      overflow: hidden; } }

.overflow-y-auto {
  overflow-y: auto; }

.editorblock p {
  margin-top: 30px; }

.editorblock ul li {
  list-style: none;
  background-image: url("../../static/svg/bullet.png");
  background-repeat: no-repeat;
  padding-left: 30px;
  width: 90%;
  margin-bottom: 1rem; }

.editorblock p {
  margin-left: 40px; }

.blockarea img {
  width: 100%;
  height: auto; }

.blockarea .main-area img {
  width: auto;
  height: auto;
  max-width: 100%; }

.file-icon {
  width: 20px;
  height: 100%; }

.d-contents {
  display: contents; }

section.blockarea div.full-bleed {
  padding: 3rem 0; }
  section.blockarea div.full-bleed--gray {
    background-color: #f7f7f7; }
  section.blockarea div.full-bleed--gray-center {
    text-align: center;
    background-color: #f7f7f7; }
  section.blockarea div.full-bleed--light-gray {
    background-color: #fbfbfb; }
  section.blockarea div.full-bleed--orange {
    background-color: #D13138; }
  @media (max-width: 991.98px) {
    section.blockarea div.full-bleed {
      padding: 2rem 0; } }

section.blockarea div.center-container {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

section.blockarea .resources {
  padding-bottom: 4rem; }

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1; }

.border--gray {
  border: 1px solid #F4F4F4; }

.box-shadow {
  box-shadow: 0 4px 12px 0 rgba(179, 179, 179, 0.1); }

@media (min-width: 992px) {
  .right-content {
    margin-left: 4rem; } }

/* Registration Page */
.flex-row {
  display: flex; }
  .flex-row div.input-container {
    margin-right: 1rem; }

h1,
h2,
h3,
h4 {
  color: #444444; }

p {
  color: #f8f8f8; }

a {
  color: #D13138; }

h2, .h2 {
  font-size: 1.5rem; }

h3, .h3 {
  font-size: 1.25rem; }

h4,
.h4,
h5,
.h5 {
  font-size: 1.125rem; }

a.locked::after,
td.locked::after {
  content: url("../../assets/svg/regular/icon-lock.svg");
  height: 15px;
  width: 15px;
  padding-right: 0px;
  display: inline-block;
  position: relative;
  top: 3px;
  left: 1rem; }

.main-area ul {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
  text-indent: -1em; }
  .main-area ul li {
    padding-bottom: 0.75rem; }
  .main-area ul li::before {
    content: "•";
    font-weight: 900;
    color: #D13138;
    padding-right: 10px; }

.main-area .breadcrumbs ul li:before {
  content: none; }

@media (max-width: 991.98px) {
  .about .preamble {
    font-size: 1.5rem; } }

.header {
  border-bottom: 1px solid #d8d8d8; }
  .header .container--wide {
    max-width: 1840px; }
  .header .header-content {
    height: 100px; }
    @media (max-width: 991.98px) {
      .header .header-content {
        height: auto; } }
  .header input::placeholder {
    font-size: 15px; }
  .header .search-container {
    display: flex; }
  .header .search-input {
    width: 242px;
    height: 49px;
    padding-left: 2.5em;
    padding-top: 16px;
    padding-bottom: 14px;
    border-radius: 3px;
    border: 1px solid #e5e5e5;
    color: #444; }
  .header .nav-selected {
    color: #D13138;
    background-color: transparent;
    border-right: none; }
  .header #header_searchfield {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 991.98px) {
      .header #header_searchfield {
        display: none; } }
  .header .svg-icon {
    position: absolute;
    left: 1em;
    width: 1em;
    height: 1em; }

.logo-image {
  width: 12rem;
  height: auto; }
  @media (max-width: 991.98px) {
    .logo-image {
      display: none; } }

section.blockarea {
  height: auto;
  margin: 0; }
  section.blockarea .hero {
    position: relative;
    min-height: 320px;
    width: 100%;
    background-size: cover;
    background-position: center center; }
    @media (min-width: 768px) {
      section.blockarea .hero {
        height: 300px; } }
    @media (min-width: 992px) {
      section.blockarea .hero {
        height: 450px; } }
    @media (min-width: 1200px) {
      section.blockarea .hero {
        height: 580px; } }
    section.blockarea .hero-text {
      position: absolute;
      top: 50%;
      left: 0%;
      width: 67%;
      padding: 1.5rem; }
      @media (min-width: 576px) {
        section.blockarea .hero-text {
          top: 40%;
          left: 7%;
          width: 64%; } }
      @media (min-width: 768px) {
        section.blockarea .hero-text {
          top: 50%; } }
      @media only screen and (min-width: 1400px) {
        section.blockarea .hero-text {
          width: 45%;
          left: 25%; } }
    section.blockarea .hero-title {
      font-size: 1.5rem;
      line-height: 1.3; }
      @media (min-width: 576px) {
        section.blockarea .hero-title {
          font-size: 1.7rem; } }
      @media (min-width: 992px) {
        section.blockarea .hero-title {
          font-size: 3rem; } }
    section.blockarea .hero-highlight {
      /*
            display: inline;
            background: $color-primary;
            color: white;
            line-height: 2.7rem;
            padding-left: 8px;
            padding-right: 8px;
            -webkit-box-decoration-break: clone;
            -ms-box-decoration-break: clone;
            -o-box-decoration-break: clone;
            box-decoration-break: clone;
            opacity: 0.9;
            */
      display: inline;
      /* color: #4a4a4a; */
      line-height: 1.7rem;
      padding-left: 8px;
      padding-right: 8px;
      -webkit-box-decoration-break: clone;
      -ms-box-decoration-break: clone;
      -o-box-decoration-break: clone;
      box-decoration-break: clone;
      opacity: 1;
      color: #FFFFFF;
      text-shadow: 3px 2px 13px rgba(0, 0, 0, 0.25); }
      @media (min-width: 992px) {
        section.blockarea .hero-highlight {
          line-height: 1.3em;
          padding: .5rem;
          padding-left: 25px;
          padding-right: 25px; } }
  section.blockarea .campaign-hero {
    position: relative;
    width: 100%;
    min-height: 320px;
    background-color: #D13237; }
    section.blockarea .campaign-hero .hero-img {
      background-size: contain;
      margin: 0 auto;
      height: 500px;
      width: 350px;
      background-repeat: no-repeat;
      display: none; }
      @media (min-width: 768px) {
        section.blockarea .campaign-hero .hero-img {
          height: 500px;
          width: 750px;
          display: block; } }
      @media (min-width: 992px) {
        section.blockarea .campaign-hero .hero-img {
          height: 500px;
          width: 1600px;
          display: block; } }
    section.blockarea .campaign-hero .hero-img-mobile {
      background-size: contain;
      margin: 0 auto;
      height: 800px;
      width: 375px;
      background-repeat: no-repeat;
      display: block; }
      @media (min-width: 768px) {
        section.blockarea .campaign-hero .hero-img-mobile {
          display: none; } }
    section.blockarea .campaign-hero .prod-img {
      background-size: auto 5%;
      background-position: 50% 25%;
      background-repeat: no-repeat;
      background-color: #FFFFFF;
      height: 800px; }
      @media (min-width: 768px) {
        section.blockarea .campaign-hero .prod-img {
          height: 500px;
          background-size: auto 20%;
          background-position: center center; } }

.login-container, .forgot-container {
  background: #f5f5f5;
  padding: 2rem 1rem;
  margin-bottom: 2rem; }

.login-input {
  width: 100%;
  height: 49px;
  padding-left: 1.5em;
  padding-top: 16px;
  padding-bottom: 14px;
  border-radius: 3px;
  border: 1px solid #e5e5e5;
  color: #444;
  background-color: #FFFFFF; }

.login-button {
  margin-top: 1em;
  display: inline-block;
  background-color: #d63740;
  color: #fff;
  border-radius: 4px;
  font-size: 1.2rem;
  padding: .75rem 1.5rem; }

div.product-image-hero-wrapper {
  position: relative;
  width: 100%; }
  div.product-image-hero-wrapper div.color-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.85; }
    div.product-image-hero-wrapper div.color-overlay--transparent {
      background-color: rgba(0, 0, 0, 0.25); }
    div.product-image-hero-wrapper div.color-overlay--fullopaque {
      background-color: #D13138;
      opacity: 1; }
      div.product-image-hero-wrapper div.color-overlay--fullopaque .svg-block {
        opacity: 1; }
    div.product-image-hero-wrapper div.color-overlay--green {
      background: radial-gradient(circle, #09aa1f 0%, #08a003 100%); }
  div.product-image-hero-wrapper img.product-hero-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; }
  div.product-image-hero-wrapper .prod-hero {
    height: 400px;
    width: 100%;
    background-size: cover;
    position: relative; }
  div.product-image-hero-wrapper .prod-logo {
    width: 70%;
    margin-bottom: 1em; }
    @media (max-width: 991.98px) {
      div.product-image-hero-wrapper .prod-logo {
        width: 100%; } }

div.product-hero-text {
  padding: 5rem 0;
  text-shadow: 3px 2px 13px rgba(0, 0, 0, 0.25); }
  div.product-hero-text p {
    text-align: left;
    color: #fff;
    font-size: 1.25rem;
    z-index: 1;
    position: relative; }
    div.product-hero-text p.logo-text {
      font-size: 1.5rem; }
  div.product-hero-text img {
    z-index: 1;
    position: relative; }
  div.product-hero-text h1.title {
    font-size: 2.333rem;
    text-align: center;
    margin: 1rem 0;
    color: #FFFFFF;
    z-index: 1;
    position: relative; }
    @media (max-width: 991.98px) {
      div.product-hero-text h1.title {
        font-size: 1.6rem; } }

.sidebar .nav-parent {
  padding: 0 .4rem .4rem 0; }
  .sidebar .nav-parent a {
    display: block;
    font-size: 1.445rem;
    position: relative;
    color: #777777;
    padding-left: 2rem; }
    .sidebar .nav-parent a:before {
      content: '\279C';
      color: #D13138;
      display: inline-block;
      position: absolute;
      font-size: 1.2rem;
      line-height: 1.5em;
      left: 0px;
      width: 28px;
      transform: rotate(180deg); }
    .sidebar .nav-parent a:hover {
      color: #D13138; }

.sidebar .nav {
  list-style-type: none;
  padding: 0;
  border: 1px solid #f5f5f5;
  border-bottom: none;
  background: #f5f5f5; }
  .sidebar .nav .direct-children-menu > a {
    font-size: 0.83333rem; }
  .sidebar .nav a {
    display: block;
    color: #666666;
    padding: .75rem 1rem; }
    .sidebar .nav a:hover {
      background-color: #f5f5f5;
      text-decoration: none; }
  .sidebar .nav li {
    background-color: #fff;
    border-left: 10px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5; }
    .sidebar .nav li > ul {
      padding: 0; }
  .sidebar .nav ul {
    list-style-type: none; }
    .sidebar .nav ul > li a {
      font-size: 0.83333rem; }
    .sidebar .nav ul > li:first-child {
      border-top: 1px solid #f5f5f5; }
    .sidebar .nav ul > li:last-child {
      border-bottom: none; }
  .sidebar .nav .nav-selected > a {
    border-right: 4px solid #D13138;
    background-color: #f5f5f5; }
  .sidebar .nav li.direct-children-menu a {
    border-left: 10px solid #f5f5f5; }

.main-menu {
  width: 70%; }
  .main-menu ul {
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center; }
    .main-menu ul li {
      margin-right: 5%; }
    .main-menu ul a {
      color: #444444; }
      .main-menu ul a:hover {
        color: #D13138; }
  @media (max-width: 991.98px) {
    .main-menu {
      display: none; } }

/*
@include media-breakpoint-down(md) {
    
    .main-menu, #header_searchfield {
        display: none;
    }

}
*/
#cssmenu {
  display: none; }

@media (max-width: 991.98px) {
  body {
    margin: 0;
    padding: 0; }
  *:focus {
    outline: none; }
  #cssmenu,
  #cssmenu ul,
  #cssmenu ul li,
  #cssmenu ul li a,
  #cssmenu #menu-button {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    line-height: 1;
    display: block;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  a.active-page {
    background-color: #eaeaea !important;
    border-right: 3px solid #D13138 !important; }
  #cssmenu {
    top: 0;
    left: 0;
    font-family: 'Source Sans Pro';
    background: #ffffff;
    /*
		* First Level ul
		*/ }
    #cssmenu .menu-wrapper {
      display: flex;
      justify-content: space-around;
      align-content: center;
      padding: 0 15px; }
      #cssmenu .menu-wrapper div.logo img {
        width: 200px;
        height: auto;
        padding: 10px; }
      #cssmenu .menu-wrapper div.search-hamburger {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%; }
        #cssmenu .menu-wrapper div.search-hamburger div#search-bar, #cssmenu .menu-wrapper div.search-hamburger div#click-me {
          display: flex;
          justify-content: center;
          flex-direction: column; }
          #cssmenu .menu-wrapper div.search-hamburger div#search-bar p, #cssmenu .menu-wrapper div.search-hamburger div#click-me p {
            padding: 0;
            margin: 0; }
          #cssmenu .menu-wrapper div.search-hamburger div#search-bar.color-search svg.svg-icon, #cssmenu .menu-wrapper div.search-hamburger div#click-me.color-search svg.svg-icon {
            fill: #cccccc;
            width: 1.4rem;
            transition: all 0.2s; }
          #cssmenu .menu-wrapper div.search-hamburger div#search-bar svg.svg-icon, #cssmenu .menu-wrapper div.search-hamburger div#click-me svg.svg-icon {
            position: static;
            width: 1.6rem;
            height: 1.6rem;
            margin-right: 20px; }
        #cssmenu .menu-wrapper div.search-hamburger div#click-me {
          position: relative; }
          #cssmenu .menu-wrapper div.search-hamburger div#click-me span.hamburger {
            display: block;
            height: 43px;
            width: 53px;
            position: relative; }
            #cssmenu .menu-wrapper div.search-hamburger div#click-me span.hamburger:before {
              position: absolute;
              top: 16px;
              right: 17px;
              display: block;
              height: 2px;
              width: 20px;
              background: #666666;
              content: ''; }
            #cssmenu .menu-wrapper div.search-hamburger div#click-me span.hamburger:after {
              position: absolute;
              top: 22px;
              right: 17px;
              display: block;
              height: 4px;
              width: 20px;
              border-top: 2px solid #666666;
              border-bottom: 2px solid #666666;
              content: ''; }
            #cssmenu .menu-wrapper div.search-hamburger div#click-me span.hamburger.menu-opened:before {
              top: 23px;
              right: 21px;
              background: #666666;
              width: 15px;
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg); }
            #cssmenu .menu-wrapper div.search-hamburger div#click-me span.hamburger.menu-opened:after {
              top: 23px;
              right: 21px;
              border: 0;
              height: 2px;
              width: 15px;
              background: #666666;
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg); }
    #cssmenu #menu-button {
      display: block;
      cursor: pointer;
      height: 80px; }
      #cssmenu #menu-button img {
        padding-left: 15px;
        width: 50%;
        height: auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%); }
    #cssmenu div.searchBar {
      display: none;
      width: 100%;
      padding: 10px; }
      #cssmenu div.searchBar input {
        padding: 5px; }
    #cssmenu ul {
      width: 100%;
      display: none;
      background-color: #ffffff;
      z-index: 999;
      position: absolute; }
      #cssmenu ul li {
        width: 100%;
        border-top: 1px solid #f5f5f5; }
        #cssmenu ul li a {
          width: 100%;
          color: #000000;
          font-weight: 400;
          padding: 17px;
          font-size: 12px;
          letter-spacing: 1px;
          text-decoration: none;
          color: #000000;
          font-weight: 700;
          text-transform: uppercase; }
        #cssmenu ul li span.submenu-button {
          position: absolute;
          z-index: 99;
          right: 0;
          top: 0;
          display: block;
          border-left: 1px solid #f5f5f5;
          height: 46px;
          width: 46px;
          cursor: pointer; }
          #cssmenu ul li span.submenu-button.submenu-opened {
            background-color: #ececec; }
            #cssmenu ul li span.submenu-button.submenu-opened:after {
              background: #666666; }
            #cssmenu ul li span.submenu-button.submenu-opened:before {
              display: none; }
          #cssmenu ul li span.submenu-button:after {
            position: absolute;
            top: 22px;
            right: 19px;
            width: 8px;
            height: 2px;
            display: block;
            background: #666666;
            content: ''; }
          #cssmenu ul li span.submenu-button:before {
            position: absolute;
            top: 19px;
            right: 22px;
            display: block;
            width: 2px;
            height: 8px;
            background: #666666;
            content: ''; }
        #cssmenu ul li ul {
          position: relative;
          left: 0;
          width: 100%;
          margin: 0;
          text-align: left;
          background-color: #ffffff;
          /*
					box-shadow: 
					inset 0px 11px 8px -10px #ececec,
					inset 0px -11px 8px -10px #ececec;
					*/ }
          #cssmenu ul li ul li {
            height: auto;
            font-size: 15px;
            border-left: 15px solid #ececec;
            width: 100%;
            transition: all 3s; }
            #cssmenu ul li ul li a {
              color: #666666; }
            #cssmenu ul li ul li span.submenu-button {
              position: absolute;
              z-index: 99;
              right: 0;
              top: 0;
              display: block;
              border-left: 1px solid #ececec;
              height: 45px;
              width: 45px;
              cursor: pointer; }
              #cssmenu ul li ul li span.submenu-button:before {
                top: 20px;
                right: 21px;
                background: #000000; }
              #cssmenu ul li ul li span.submenu-button:after {
                top: 23px;
                right: 18px;
                background: #000000; }
              #cssmenu ul li ul li span.submenu-button.submenu-opened {
                background-color: #ececec; }
                #cssmenu ul li ul li span.submenu-button.submenu-opened:after {
                  background: #666666; }
                #cssmenu ul li ul li span.submenu-button.submenu-opened:before {
                  display: none; }
            #cssmenu ul li ul li span.submenu-locked {
              position: absolute;
              z-index: 99;
              right: 0;
              top: 0;
              display: block;
              border-left: 1px solid #ececec;
              height: 45px;
              width: 45px;
              cursor: pointer;
              background-color: #ffffff; }
              #cssmenu ul li ul li span.submenu-locked:before {
                display: none;
                top: 20px;
                right: 21px;
                background: #000000; }
              #cssmenu ul li ul li span.submenu-locked:after {
                display: inline-block;
                position: relative;
                top: 50%;
                left: 50%;
                margin: 0 auto;
                transform: translate(-50%, -50%);
                content: url("../../assets/svg/regular/icon-lock.svg");
                height: 15px;
                width: 15px; }
              #cssmenu ul li ul li span.submenu-locked.submenu-opened {
                background-color: #ececec; }
                #cssmenu ul li ul li span.submenu-locked.submenu-opened:after {
                  background: #666666; }
                #cssmenu ul li ul li span.submenu-locked.submenu-opened:before {
                  display: none; }
            #cssmenu ul li ul li span.submenu-register {
              position: absolute;
              z-index: 99;
              right: 0;
              top: 0;
              display: block;
              border-left: 1px solid #ececec;
              height: 45px;
              width: 45px;
              cursor: pointer;
              background-color: #ffffff; }
              #cssmenu ul li ul li span.submenu-register:before {
                display: none;
                top: 20px;
                right: 21px;
                background: #000000; }
              #cssmenu ul li ul li span.submenu-register:after {
                display: inline-block;
                position: relative;
                top: 50%;
                left: 50%;
                margin: 0 auto;
                transform: translate(-50%, -50%);
                content: "+";
                font-weight: 700;
                padding: 0;
                height: 17px;
                width: 17px;
                border-radius: 10px;
                background-color: #efefef;
                color: #0091d2;
                text-align: center; }
              #cssmenu ul li ul li span.submenu-register.submenu-opened {
                background-color: #ececec; }
                #cssmenu ul li ul li span.submenu-register.submenu-opened:after {
                  background: #666666; }
                #cssmenu ul li ul li span.submenu-register.submenu-opened:before {
                  display: none; }
  #cssmenu ul ul li,
  #cssmenu li:hover > ul > li {
    height: auto; }
  #cssmenu > ul > li.has-sub > a:after,
  #cssmenu > ul > li.has-sub > a:before,
  #cssmenu ul ul > li.has-sub > a:after,
  #cssmenu ul ul > li.has-sub > a:before {
    display: none; } }

button {
  border-radius: 0.3rem;
  border: none;
  background-color: #fff;
  color: #0091d2;
  font-size: 1.4rem;
  padding: 0.5rem 3rem;
  font-weight: 700;
  cursor: pointer; }

.button {
  border: none;
  background-color: #fff;
  color: #222;
  font-size: .9rem;
  padding: 1rem 2rem;
  cursor: pointer; }
  .button__transparent {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff; }
    .button__transparent:hover {
      background-color: #fff;
      color: #222; }

.go-button {
  margin-top: 1em;
  display: inline-block;
  background-color: #d63740;
  color: #fff;
  border-radius: 4px;
  font-size: 1.2rem;
  padding: .75rem 1.5rem; }

.link-button a {
  color: #D13138;
  font-weight: 600; }
  .link-button a:after {
    content: "\02192";
    width: 1rem;
    height: 1rem;
    margin-left: .4rem;
    font-size: 1rem; }
  .link-button a:hover {
    text-decoration: none; }

.search-button {
  background: #d13138;
  text-shadow: none;
  margin-left: 1rem;
  padding: .3rem 1.3rem;
  height: auto;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 3px; }

.breadcrumbs ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin: 0.5rem 0; }
  .breadcrumbs ul li {
    margin-right: 4px;
    color: #D13138; }
    .breadcrumbs ul li a {
      font-size: .9444rem;
      color: #444444; }
      .breadcrumbs ul li a.last {
        color: #777777;
        color: #9b9b9b; }
      .breadcrumbs ul li a:hover {
        color: #D13138; }

.checklist {
  list-style: none;
  padding: .75em;
  padding-bottom: 0;
  columns: 1 175px; }
  .checklist li {
    display: flex;
    flex-flow: wrap;
    position: relative;
    width: 100%;
    break-inside: avoid;
    width: 100%;
    padding-left: 3em;
    margin-bottom: 1em;
    font-family: 'Source Sans Pro';
    font-weight: 300; }
    .checklist li:before, .checklist li:after {
      display: inline-flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      width: 1.5em;
      height: 1.5em;
      line-height: 1;
      left: 0;
      top: 0; }
    .checklist li:before {
      content: '';
      border: 1px solid #D13138;
      border-radius: 100%; }
    .checklist li:after {
      position: absolute;
      font-size: .8em;
      font-weight: bold;
      width: 1ex;
      height: 2ex;
      content: '';
      border-right: 1px solid #D13138;
      border-bottom: 1px solid #D13138;
      transform: rotate(45deg);
      left: 1.4ex;
      top: .3em; }

.footer-container {
  flex-direction: column-reverse;
  color: #e6e6e6; }

div.menu-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 0; }
  div.menu-container div.menu-items {
    margin-bottom: 30px;
    padding: 10px;
    padding-right: 30px; }
    div.menu-container div.menu-items a {
      color: #fff;
      font-size: 14px; }
    div.menu-container div.menu-items ul {
      margin: 0;
      padding: 0;
      list-style-type: none; }
      div.menu-container div.menu-items ul li {
        font-size: 18px;
        padding: .2rem 0; }
    div.menu-container div.menu-items h3 {
      font-weight: 700;
      padding-bottom: 1rem; }
      div.menu-container div.menu-items h3 a {
        font-size: 1.125rem; }

div.contact-container {
  margin: 30px 0; }
  div.contact-container .svg-icon-hldisplay-logo {
    fill: #fff; }
  div.contact-container img.footer-logo {
    max-width: 100%;
    width: auto;
    height: auto;
    margin-bottom: 30px;
    align-self: center; }
  div.contact-container div.social-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
    div.contact-container div.social-icons img {
      padding: 5px;
      width: auto;
      height: 100%; }

p.copyright {
  color: #e6e6e6;
  font-size: 1rem;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px; }

@media (min-width: 768px) {
  .footer-container {
    flex-direction: row; }
    .footer-container div.menu-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap; }
      .footer-container div.menu-container div.menu-items {
        margin-right: 20px; }
        .footer-container div.menu-container div.menu-items h3 {
          font-size: 1.5rem;
          font-weight: 700;
          color: #fff; }
    .footer-container div.contact-container img.footer-logo {
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      margin-bottom: 30px;
      align-self: center; }
    .footer-container div.contact-container div.social-icons {
      justify-content: center; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .footer-container {
    flex-direction: column-reverse; }
    .footer-container div.menu-container {
      flex-direction: row;
      margin-top: 0; }
    .footer-container div.contact-container {
      flex-direction: row; }
      .footer-container div.contact-container img.footer-logo {
        margin: 0;
        margin-bottom: 30px; }
      .footer-container div.contact-container div.social-icons {
        justify-content: flex-start; } }

#registration_container {
  margin-bottom: 2rem; }
  #registration_container > .box-rounded {
    padding: 2rem;
    background: #f7f7f7;
    overflow: hidden; }
  #registration_container > .container > .box-content {
    padding: 2rem;
    background: #f7f7f7;
    overflow: hidden; }
  #registration_container .box-style-inactive {
    margin: 1rem 0; }
  #registration_container h2 {
    font-weight: 600;
    font-size: 1.5rem; }
  #registration_container h3 {
    margin: 1rem 0;
    margin-top: 2rem; }
  #registration_container .button[href*="software/score"] {
    color: #D13138;
    background: none; }
  #registration_container .button {
    background: #D13138;
    text-shadow: none;
    margin: 1rem 1rem 0 0;
    padding: .3rem 1.3rem;
    height: auto;
    color: #fff;
    font-size: 1rem;
    font-weight: normal;
    border-radius: 3px;
    margin-bottom: 2rem; }
    #registration_container .button span {
      display: none; }
  #registration_container .input-container input, #registration_container .input-container select {
    border: 1px solid #e5e5e5;
    padding: .5rem; }
  #registration_container .input-container textarea {
    width: 100%; }
  #registration_container .form-container table, #registration_container .form-container tr, #registration_container .form-container td {
    display: block;
    width: auto !important; }
  #registration_container .form-container td {
    margin-top: .5rem; }
  #registration_container .form-container label {
    font-size: 0.8235rem;
    margin: 0;
    font-weight: bold;
    color: #707070;
    font-family: 'Segoe UI'; }

.sub-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*
    .locked::after {
        display: inline-block;
        margin-left: 10px;
        content: "\1F512";
        text-decoration: none;
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    */ }
  .sub-links .sub-link {
    padding: 10px;
    flex-grow: 1;
    width: 100%;
    border: 1px solid #CCC;
    border-bottom: none;
    margin-left: -1px;
    color: #666;
    text-align: center; }
    .sub-links .sub-link:first-child {
      border-left: none; }
    .sub-links .sub-link:last-child {
      border-right: none; }
  .sub-links .register::after {
    display: inline-block;
    margin-left: 10px;
    content: "+";
    font-weight: 700;
    text-decoration: none;
    padding: 0;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    background-color: #efefef;
    color: #D13138; }

.left-content img {
  width: 100%;
  height: auto; }

p {
  color: #777777; }

.gray-container {
  background-color: #FAFAFA;
  border: 1px solid #EBEBEB;
  border-radius: 2px 15px 2px 15px;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px; }
  .gray-container a {
    color: #3993C3;
    text-decoration: underline; }

.beige-container {
  background-color: #f7f1b7;
  border: 1px solid #e6da67;
  border-radius: 2px 15px 2px 15px;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px; }
  .beige-container a {
    color: #3993C3;
    text-decoration: underline; }

.mob-content {
  display: block; }
  @media (min-width: 768px) {
    .mob-content {
      display: none; } }

.desktop-content {
  display: none; }
  @media (min-width: 768px) {
    .desktop-content {
      display: block; } }

/* div.full-bleed {
    // background: url('https://placeimg.com/640/480/nature');
    background-size: cover;
    background-position: center center;
    height: 40vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .hero {

        p {
            color: #ffffff;
            font-size: 42px;
        }
        
    }
} */
#bg {
  width: 100vw;
  padding: 0;
  height: 40vh;
  object-fit: cover; }

#full {
  max-width: 100vw;
  padding: 0; }

#center {
  margin: 0 auto;
  display: block; }

#max {
  width: 100%;
  height: auto; }

.textlinkblock {
  text-align: center;
  color: #fff; }
  .textlinkblock h2 {
    color: #fff; }
  .textlinkblock .text {
    color: #fff;
    font-weight: 400;
    font-size: 20px; }
  .textlinkblock .button {
    display: inline-block;
    text-transform: uppercase;
    color: #4a4a4a;
    font-weight: 600;
    font-size: .8rem;
    margin-top: 1.5rem; }
  .textlinkblock .button:hover {
    color: #4a4a4a;
    text-decoration: underline; }

.product-page-list {
  display: flex;
  flex-flow: wrap; }
  .product-page-list .heading {
    display: block;
    width: 100%;
    text-align: center;
    padding: 1rem 0 .5rem;
    color: #D13138; }
  .product-page-list .product-item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 48%;
    padding: 1.25rem;
    border-radius: 3px;
    margin: 1%;
    background-color: #fff;
    height: auto; }
    @media (max-width: 991.98px) {
      .product-page-list .product-item {
        width: 96%;
        margin-bottom: 1rem;
        flex-direction: column; } }
  .product-page-list .product-image-container {
    width: 28%;
    flex-basis: 28%; }
    @media (max-width: 991.98px) {
      .product-page-list .product-image-container {
        width: 50%;
        flex-basis: 50%; } }
  .product-page-list .product-image {
    width: 100%;
    padding-right: 16%; }
    @media (max-width: 991.98px) {
      .product-page-list .product-image {
        padding: 8% 0; } }
  .product-page-list .product-info {
    width: 100%; }
  .product-page-list .product-title {
    margin-bottom: .75rem;
    font-size: 1.5rem;
    color: #444444; }
    .product-page-list .product-title .title-part {
      color: #D13138; }
    .product-page-list .product-title a {
      color: #444444; }
  .product-page-list .product-description:last-child {
    margin-bottom: 0; }

.software-item-container {
  flex: 48%;
  border-radius: 3px;
  margin: 1%;
  background-color: #fff; }
  @media (max-width: 991.98px) {
    .software-item-container {
      flex: 96%;
      margin-bottom: 1rem; } }
  .software-item-container div.description-container {
    padding: 2rem;
    height: 15rem;
    display: flex;
    flex-direction: row; }
    @media (max-width: 991.98px) {
      .software-item-container div.description-container {
        flex-direction: column;
        height: auto; } }
    .software-item-container div.description-container div.software-image {
      width: 15%;
      padding-right: 30px; }
      @media (max-width: 991.98px) {
        .software-item-container div.description-container div.software-image {
          margin-bottom: 30px;
          padding-right: 0px; } }
    .software-item-container div.description-container div.description {
      width: 85%; }
      @media (max-width: 991.98px) {
        .software-item-container div.description-container div.description {
          width: 100%; } }
    .software-item-container div.description-container .arrow_link {
      margin-bottom: 1rem;
      font-size: 1.5rem; }
      .software-item-container div.description-container .arrow_link a {
        font-family: "Source Sans Pro";
        font-weight: normal;
        font-weight: 500;
        color: #444444; }

.resource-container {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  align-items: center; }
  .resource-container .download-icon {
    padding-right: 20px; }
    .resource-container .download-icon svg {
      width: 48px;
      height: 48px; }
  .resource-container .resource-content a {
    color: #000;
    font-weight: 700; }
  .resource-container .resource-content p {
    margin: 0; }

hr.resource-divider {
  margin-bottom: 30px;
  padding: 0;
  border: 1px solid #ececec; }

.installer-container {
  border: 1px solid #EBEBEB;
  border-radius: 2px 15px 2px 15px;
  margin-bottom: 60px; }
  .installer-container.bg-color-beige {
    background-color: #f7f1b7;
    border: 1px solid #e6da67; }
  .installer-container h2 {
    padding: 0.4em;
    font-size: 22px;
    margin: 0;
    border-bottom: 1px solid #e5e5e5; }
    .installer-container h2.line-color-beige {
      border-bottom: 1px solid #e6da67; }
  .installer-container span.desc {
    width: 150px;
    display: inline-block;
    color: #777777; }
  .installer-container p.underlined {
    padding: 0.4em;
    font-size: 16px;
    margin: 0;
    border-bottom: 1px solid #e5e5e5;
    color: #555555;
    display: flex;
    flex-direction: row; }
    .installer-container p.underlined.line-color-beige {
      border-bottom: 1px solid #e6da67; }
  .installer-container a {
    color: #3993C3; }
  .installer-container .download-button {
    background-color: #0091D2;
    color: #ffffff;
    border-radius: 2px;
    padding: 15px;
    margin: 15px;
    padding-right: 20px; }
    .installer-container .download-button::before {
      content: url("../../assets/svg/regular/download-installer.svg");
      height: 25px;
      width: 25px;
      padding-right: 10px;
      display: inline-block;
      position: relative;
      top: 3px; }
  .installer-container .dl-button {
    margin: 40px 5px;
    margin-bottom: 25px; }
  .installer-container p.filename {
    font-size: 13px;
    color: #777777;
    margin-left: 20px;
    margin-top: 0; }

.faq-block {
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
  margin-left: 7px;
  padding: 0.5em 0; }
  .faq-block .showMoreTrigger {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .faq-block .showMoreTrigger i.arrow {
      border: solid #D13138;
      border-width: 0 2px 2px 0;
      height: 5px;
      width: 5px;
      display: inline-block;
      padding: 5px;
      margin-right: 5px; }
    .faq-block .showMoreTrigger .arrow-up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      transition: all 0.5s;
      transform-origin: center; }
    .faq-block .showMoreTrigger .arrow-down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transition: all 0.5s;
      transform-origin: center; }
  .faq-block .showMoreContent {
    display: none;
    width: 85%; }
    .faq-block .showMoreContent a {
      color: #3993C3; }
  .faq-block strong.faq-question {
    width: 85%; }
