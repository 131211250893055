h1,
h2,
h3,
h4 {
  // color: $color-primary;
  // margin-bottom: 3rem;
  color: $color-font-heading;
}

p {
  color: $color-default-gray;
}

a {
  color: $color-primary;
}

h2,
.h2 {
  font-size: 1.5rem;
}
h3,
.h3 {
  font-size: 1.25rem;
}
h4,
.h4,
h5,
.h5 {
  font-size: 1.125rem;
}

// Locked icon
a.locked::after,
td.locked::after {
  // display: inline-block;
  // margin-left: 10px;
  // content: "\1F512";
  content: url("../../assets/svg/regular/icon-lock.svg");
  height: 15px;
  width: 15px;
  padding-right: 0px;
  display: inline-block;
  position: relative;
  top: 3px;
  left: 1rem;
}

.main-area {
  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
    text-indent: -1em;

    li {
      padding-bottom: 0.75rem;
    }

    li::before {
      content: "•";
      font-weight: 900;
      color: $color-primary;
      padding-right: 10px;
    }
  }

  //keep until moving breadcrums menu to other area
  .breadcrumbs ul li:before {
    content: none;
  }
}

.about .preamble {
  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
  }
}
