button {
    border-radius: 0.3rem;
    border: none;
    background-color: $white;
    color: $primary;
    font-size: 1.4rem;
    padding: 0.5rem 3rem;
    font-weight: 700;
    cursor: pointer;
}

.button {
    border: none;
    background-color: $color-white;
    color: $color-black;
    font-size: .9rem;
    padding: 1rem 2rem;
    cursor: pointer;

    &__transparent {
        background-color: transparent;
        border: 1px solid #fff;
        color: $color-white;

        &:hover {
            background-color: #fff;
            color: $color-black;
        }
    }
}

.go-button {
    margin-top: 1em;
    display: inline-block;
    background-color: #d63740;
    color: #fff;
    border-radius: 4px;
    font-size: 1.2rem;
    padding: .75rem 1.5rem;
}

.link-button a {
    color: $color-primary;
    font-weight: 600;
    
    &:after {
        content: "\02192";
        width: 1rem;
        height: 1rem;
        margin-left: .4rem;
        font-size: 1rem;
    }

    &:hover {
        text-decoration: none;
    }
}

.search-button {
    background: #d13138;
    text-shadow: none;
    // margin: 1rem 1rem 0 0;
    margin-left: 1rem;
    padding: .3rem 1.3rem;
    height: auto;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 3px;
}