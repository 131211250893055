
.installer-container {
    border: 1px solid #EBEBEB;
    border-radius: 2px 15px 2px 15px;
    margin-bottom: 60px;

    &.bg-color-beige {
        background-color: #f7f1b7;
        border: 1px solid #e6da67;
    }

    h2 {
        padding: 0.4em;
        font-size: 22px;
        margin: 0;
        border-bottom: 1px solid #e5e5e5;

        &.line-color-beige {
            border-bottom: 1px solid #e6da67;
        }
    }

    span.desc {
        width: 150px;
        display: inline-block;
        color: #777777;
    }

    p.underlined {
        padding: 0.4em;
        font-size: 16px;
        margin: 0;
        border-bottom: 1px solid #e5e5e5;
        color: #555555;
        display: flex;
        flex-direction: row;

        &.line-color-beige {
            border-bottom: 1px solid #e6da67;
        }
    }

    a {
        color: #3993C3;
    }

    .download-button {
        background-color: #0091D2;
        color: #ffffff;
        border-radius: 2px;
        padding: 15px;
        margin: 15px;
        padding-right: 20px;

        &::before {
            content: url('../../assets/svg/regular/download-installer.svg');
            height: 25px;
            width: 25px;
            padding-right: 10px;
            display: inline-block;
            position: relative;
            top: 3px;        
        }
    }

    .dl-button {
        margin: 40px 5px;
        margin-bottom: 25px;
    }

    p.filename {
        font-size: 13px;
        color: #777777;
        margin-left: 20px;
        margin-top: 0;
    }
}