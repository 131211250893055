﻿/* source-sans-pro-regular - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    url("../fonts/source-sans-pro-v11-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/source-sans-pro-v11-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"),
    url("../fonts/source-sans-pro-v11-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/source-sans-pro-v11-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Needed because bootstrap reboot.scss breaks concrete edit styling if box-sizing: border-box */
*,
*::before,
*::after {
  box-sizing: content-box;
}

html,
body,
.container,
.container * {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);

  @include media-breakpoint-up(xl) {
    font-size: 17px;
  }
}

div#ccm-theme-wrapper {
  width: 100% !important;
}

@include media-breakpoint-up(xxl) {
  .container {
    max-width: 1300px;
  }
}

body {
  font-size: $font-size-base;
  font-family: "Source Sans Pro", Sans-Serif;
  font-weight: normal;
  line-height: 1.5;
  color: $color-font-default;
  margin: 0;
  background-color: $color-white;
  text-align: left;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  button {
    border-radius: 0;
    font-size: 1.2rem;
    padding: 0.8rem 2.5rem;
    font-weight: 400;
  }
}

// Temp remove border from Bootstrap class
.table {
  border: none;
}

.overflow {
  &-hidden {
    overflow: hidden;

    @include media-breakpoint-down(md) {
      &-md-down {
        overflow: hidden;
      }
    }
  }

  &-y-auto {
    overflow-y: auto;
  }
}

.editorblock {
  p {
    margin-top: 30px;
  }

  ul {
    li {
      list-style: none;
      background-image: url("../../static/svg/bullet.png");
      background-repeat: no-repeat;
      padding-left: 30px;
      width: 90%;
      margin-bottom: 1rem;
    }
  }

  p {
    margin-left: 40px;
  }
}

.blockarea img {
  width: 100%;
  // width: auto;
  height: auto;
}

.blockarea .main-area img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.file-icon {
  width: 20px;
  height: 100%;
}

.d-contents {
  display: contents;
}

section.blockarea {
  div.full-bleed {
    padding: 3rem 0;

    &--gray {
      background-color: #f7f7f7;
    }
    &--gray-center {
      text-align: center;
      background-color: #f7f7f7;
    }
    &--light-gray {
      background-color: #fbfbfb;
    }
    &--orange {
      background-color: $color-primary;
    }

    @include media-breakpoint-down(md) {
      padding: 2rem 0;
    }
  }

  div.center-container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .resources {
    padding-bottom: 4rem;
  }
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.border--gray {
  border: 1px solid $color-lightgray;
}

.box-shadow {
  box-shadow: 0 4px 12px 0 hsla(0, 0%, 70%, 0.1);
}

.right-content {
  @include media-breakpoint-up(lg) {
    margin-left: 4rem;
  }
}

/* Registration Page */

.flex-row {
  display: flex;

  div.input-container {
    margin-right: 1rem;;
  }
}
