
$menu-background: #ffffff;
$selected-background: #ececec;
$border-color: #f5f5f5;

#cssmenu {
    display: none;
}

@include media-breakpoint-down(md) {

	body {
		margin: 0;
		padding: 0;
	}

	
	*:focus {
		outline: none;
	}

	#cssmenu,
	#cssmenu ul,
	#cssmenu ul li,
	#cssmenu ul li a,
	#cssmenu #menu-button {
		margin: 0;
		padding: 0;
		border: 0;
		list-style: none;
		line-height: 1;
		display: block;
		position: relative;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	a.active-page {
		background-color: #eaeaea !important;
		border-right: 3px solid $color-primary !important;
	}

	// Mobile menu "header"
	#cssmenu {
        // width: 100vw;
        // position: absolute;
        top: 0;
        left: 0;
        // z-index: 777;
		font-family: 'Source Sans Pro';
		background: $menu-background;

		.menu-wrapper {
			display: flex;
			justify-content: space-around;
			align-content: center;
			padding: 0 15px;
			// width: 100%;

			div.logo {

				img {
					width: 200px;
					height: auto;
					padding: 10px;
				}

			}

			div.search-hamburger {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
				width: 100%;

				div#search-bar, div#click-me {
					display: flex;
					justify-content: center;
					flex-direction: column;

					p {
						padding: 0;
						margin: 0;
					}
					
					&.color-search {

						svg.svg-icon {
							fill: #cccccc;
							width: 1.4rem;
							transition: all 0.2s;
						}

					}

					svg.svg-icon {
						position: static;
						width: 1.6rem;
						height: 1.6rem;
						margin-right: 20px;
					}

				}

				div#click-me {
					position: relative;

					span.hamburger {
						display: block;
						height: 43px;
						width: 53px;
						position: relative;

						// Top bar
						&:before {
							position: absolute;
							top: 16px;
							right: 17px;
							display: block;
							height: 2px;
							width: 20px;
							background: #666666;
							content: '';
						}
			
						// Middle and bottom bar
						&:after {
							position: absolute;
							top: 22px;
							right: 17px;
							display: block;
							height: 4px;
							width: 20px;
							border-top: 2px solid #666666;
							border-bottom: 2px solid #666666;
							content: '';            
						}

						// Left close bar
						&.menu-opened:before {
							top: 23px;
							right: 21px;
							background: #666666;;
							width: 15px;
							-webkit-transform: rotate(-45deg);
							-moz-transform: rotate(-45deg);
							-ms-transform: rotate(-45deg);
							-o-transform: rotate(-45deg);
							transform: rotate(-45deg);            
						}
						
						// Right close bar
						&.menu-opened:after {
							top: 23px;
							right: 21px;
							border: 0;
							height: 2px;
							width: 15px;
							background: #666666;
							-webkit-transform: rotate(45deg);
							-moz-transform: rotate(45deg);
							-ms-transform: rotate(45deg);
							-o-transform: rotate(45deg);
							transform: rotate(45deg);            
						}

					}

				}
			}
		}

		// Title (Mobile-Menu:)
		#menu-button {
			display: block;
			cursor: pointer;
			height: 80px;



			img {
				padding-left: 15px;
				width: 50%;
				height: auto;
				position: relative;
				top: 50%;
				transform: translateY(-50%);				
			}
			
		}

		div.searchBar {
			display: none;
			width: 100%;
			// background-color: #efefef;
			padding: 10px;

			input {
				padding: 5px;
			}
		}

		/*
		* First Level ul
		*/

		ul {
			width: 100%;
            display: none;
			background-color: #ffffff;
			z-index: 999;
			position: absolute;

			li {
				width: 100%;
				border-top: 1px solid #f5f5f5;

				a {
					width: 100%;
					color: #000000;
					font-weight: 400;
					padding: 17px;
					font-size: 12px;
					letter-spacing: 1px;
					text-decoration: none;
					color: #000000;
					font-weight: 700;
					text-transform: uppercase;				
				}

				// Open submenu "button"
				span.submenu-button {
					position: absolute;
					z-index: 99;
					right: 0;
					top: 0;
					display: block;
					border-left: 1px solid #f5f5f5;
					height: 46px;
					width: 46px;
					cursor: pointer;

					&.submenu-opened {
						background-color: #ececec;
						
						// -
						&:after {
							background: #666666;
						}

						// |
						&:before {
							display: none;
						}

					}
					
					// (+) Horisontal -
					&:after {
						position: absolute;
						top: 22px;
						right: 19px;
						width: 8px;
						height: 2px;
						display: block;
						background: #666666;
						content: '';
					}
					
					// (+) vertical |
					&:before {
						position: absolute;
						top: 19px;
						right: 22px;
						display: block;
						width: 2px;
						height: 8px;
						background: #666666;
						content: '';
					}

				}

				// Submenu
				ul {
					position: relative;
					left: 0;
					width: 100%;
					margin: 0;
					text-align: left;
                    background-color: #ffffff;
					// border-top: 2px solid #f8931d;
					// border-bottom: 2px solid #ececec;
                    // border-bottom: 1px solid orange;
					
					/*
					box-shadow: 
					inset 0px 11px 8px -10px #ececec,
					inset 0px -11px 8px -10px #ececec;
					*/
                    
					
					li {
						height: auto;
						font-size: 15px;
						border-left: 15px solid #ececec;
						width: 100%;
						transition: all 3s;


						a {
							color: #666666;
						}

						// + button submenu "inactive"
						span.submenu-button {
							position: absolute;
							z-index: 99;
							right: 0;
							top: 0;
							display: block;
							border-left: 1px solid #ececec;
							height: 45px;
							width: 45px;
							cursor: pointer;

							// -
							&:before {
								top: 20px;
								right: 21px;
								background: #000000;
							}							
							
							// |
							&:after {
								top: 23px;
								right: 18px;
								background: #000000;
							}

							// + button submenu "active"
							&.submenu-opened {
								background-color: #ececec;
								
								// -
								&:after {
									background: #666666;
								}

								// |
								&:before {
									display: none;
								}

							}

						}

						span.submenu-locked {
							position: absolute;
							z-index: 99;
							right: 0;
							top: 0;
							display: block;
							border-left: 1px solid #ececec;
							height: 45px;
							width: 45px;
							cursor: pointer;
							background-color:#ffffff;

							// -
							&:before {
								display: none;
								top: 20px;
								right: 21px;
								background: #000000;
							}							
							
							// |
							&:after {
								display: inline-block;
								position: relative;
								top: 50%;
								left: 50%;
								margin: 0 auto;
								transform: translate(-50%, -50%);
								// content: "\1F512";
								content: url('../../assets/svg/regular/icon-lock.svg');
								height: 15px;
								width: 15px;
								// padding-right: 10px;
								// display: inline-block;
								// position: relative;
								// top: 3px; 
								// left: 1rem;								
							}

							// + button submenu "active"
							&.submenu-opened {
								background-color: #ececec;
								
								// -
								&:after {
									background: #666666;
								}

								// |
								&:before {
									display: none;
								}

							}

						}

						
						span.submenu-register {
							position: absolute;
							z-index: 99;
							right: 0;
							top: 0;
							display: block;
							border-left: 1px solid #ececec;
							height: 45px;
							width: 45px;
							cursor: pointer;
							background-color:#ffffff;

							// -
							&:before {
								display: none;
								top: 20px;
								right: 21px;
								background: #000000;
							}							
							
							// ()
							&:after {
								display: inline-block;
								position: relative;
								top: 50%;
								left: 50%;
								margin: 0 auto;
								transform: translate(-50%, -50%);
								content: "+";
								font-weight: 700;
								padding: 0;
								height: 17px;
								width: 17px;
								border-radius: 10px;
								background-color: #efefef;
								// background: linear-gradient(to right, #ffdb79, $color-primary);
								color: #0091d2;
								text-align: center;
							}

							// + button submenu "active"
							&.submenu-opened {
								background-color: #ececec;
								
								// -
								&:after {
									background: #666666;
								}

								// |
								&:before {
									display: none;
								}

							}

						}
											

						&.has-sub {

							a:after {
								// display: none;
							}

							a:before {
								// display: none;
							}
							
						}
						
						
					}
				
				}

				&:first-child {
					// border-top: 1px solid #cccccc;
				}

				&:last-child {
					// border-bottom: 1px solid #cccccc;
				}

			}

		}


		

	}


	// Submenu height
	#cssmenu ul ul li,
	#cssmenu li:hover > ul > li {
		height: auto;
	}

	// Hide +/- icon if li has submenu
	#cssmenu > ul > li.has-sub > a:after,
	#cssmenu > ul > li.has-sub > a:before,
	#cssmenu ul ul > li.has-sub > a:after,
	#cssmenu ul ul > li.has-sub > a:before {
		display: none;
	}



}
