$color-white: #fff;
$color-black: #222;
$color-dark: #4a4a4a;
$color-default-gray: #f8f8f8;
$color-lightgray: #F4F4F4;
$color-gray: #e6e6e6;
$color-mediumgray: #888888;
$color-darkgray: #7a7a7a;
$color-darkgrayer: #404040;
// $color-blue: #0091d2;

$color-primary: #D13138;
$color-font-default: #777777;
$color-font-heading: #444444;
$color-font-sidebar-nav: #666666;

.bg {
    &-white {
        background-color: $color-white;
    }

    &-dark {
        background-color: $color-dark;
    }

    &-gray {
        background-color: $color-gray;
    }

    &-darkgray {
        background-color: $color-darkgray;
    }

    &-lightgray {
        background-color: $color-lightgray;
    }

    &-theme-gray-0 {
        background-color: $color-default-gray;
    }

    &-blue {
       // background-color: $color-blue;
    }
}

.block {
    background-color: transparent;
    background-clip: content-box;

    &.bg {
        &-white {
            background-color: $color-white;
        }

        &-dark {
            background-color: $color-dark;
        }

        &-gray {
            background-color: $color-gray;
        }

        &-darkgray {
            background-color: $color-darkgray;
        }

        &-lightgray {
            background-color: $color-lightgray;
        }

        &-theme-gray-0 {
            background-color: $color-default-gray;
        }

        &-blue {
            // background-color: $color-blue;
        }
    }
}

.svg-icon {
    width: 2em;
    height: 2em;
    flex: 0 0 auto;
    transition: fill .25s ease-in-out;

    &--gray {
        fill: #A4A4A4; 
        fill-rule: evenodd;
    }

    &--white {
        fill: #fff;
        fill-rule: evenodd;

        @at-root a:hover #{&} {
            fill: darken(#fff, 10%);
        }
    }

    &--black {
        fill: #000;
        fill-rule: evenodd;

        @at-root a:hover #{&} {
            fill: darken(#000, 10%);
        }
    }


}
