
// Children to software pages
.sub-links {
    display:  flex;
    flex-direction: row;
    justify-content: space-between;

    .sub-link {
        padding: 10px;
        flex-grow: 1;
        width: 100%;
        border: 1px solid #CCC;
        border-bottom: none;
        margin-left: -1px;
        color: #666;
        text-align: center;

        &:first-child {
            border-left: none;
        }

        &:last-child {
            border-right: none;
        }
    }

    /*
    .locked::after {
        display: inline-block;
        margin-left: 10px;
        content: "\1F512";
        text-decoration: none;
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    */

    .register::after {
        display: inline-block;
        margin-left: 10px;
        content: "+";
        font-weight: 700;
        text-decoration: none;
        padding: 0;
        height: 25px;
        width: 25px;
        border-radius: 25px;
        background-color: #efefef;
        color: $color-primary;
    }

}

.left-content img {
    width: 100%;
    height: auto;
}