.checklist {
    list-style: none;
    // margin-bottom: 2em;
    padding: .75em;
    padding-bottom: 0;
    columns: 1 175px;

    li {
        display: flex;
        flex-flow: wrap;
        position: relative;
        width: 100%;
        break-inside: avoid;
        width: 100%;
        padding-left: 3em;
        margin-bottom: 1em;
        font-family: 'Source Sans Pro'; 
        font-weight: 300;

        &:before, &:after {
            display: inline-flex;
            position: absolute;
            // -webkit-box-pack: center;
            // -webkit-justify-content: center;
            // -ms-flex-pack: center;
            justify-content: center;
            // -webkit-box-align: center;
            // -webkit-align-items: center;
            //  -ms-flex-align: center;
            align-items: center;
            width: 1.5em;
            height: 1.5em;
            line-height: 1;
            left: 0;
            top: 0;
        }
        &:before {
            content: '';
            border: 1px solid $color-primary;
            border-radius: 100%;
        }
        &:after {
            position: absolute;
            font-size: .8em;
            font-weight: bold;
            width: 1ex;
            height: 2ex;
            content: '';
            border-right: 1px solid $color-primary;
            border-bottom: 1px solid $color-primary;
            transform: rotate(45deg);
            left: 1.4ex;
            top: .3em;
        }

    }
}