.faq-block {
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;
    margin-left: 7px;
    padding: 0.5em 0;

    .showMoreTrigger {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        i.arrow{
            border: solid $color-primary; 
            border-width: 0 2px 2px 0;
            height: 5px;
            width: 5px;
            display: inline-block;
            padding: 5px;
            margin-right: 5px;
        }
        
        .arrow-up {
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
            transition: all 0.5s;
            transform-origin: center;
        }
        
        .arrow-down {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transition: all 0.5s;
            transform-origin: center;
        }
        
    }

    .showMoreContent{
        display: none;
        width: 85%;

        a {
            color: #3993C3;        
        }

    }

    strong.faq-question {
        width: 85%;
    }

}