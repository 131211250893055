#registration_container {
    margin-bottom: 2rem;

    > .box-rounded {
        padding: 2rem;
        background: #f7f7f7;
        overflow: hidden;
    }
    
    > .container > .box-content {
        padding: 2rem;
        background: #f7f7f7;
        overflow: hidden;
    }

    .box-style-inactive {
        margin: 1rem 0;
    }

    h2 {
        font-weight: 600;
        font-size: 1.5rem;
    }
    h3 {
        margin: 1rem 0;
        margin-top: 2rem;
    }

    .button[href*="software/score"] {
        color: $color-primary;
        background: none;
    }

    .button {
        background: $color-primary;
        text-shadow: none;
        margin: 1rem 1rem 0 0;
        padding: .3rem 1.3rem;
        height: auto;
        color: $color-white;
        font-size: 1rem;
        font-weight: normal;
        border-radius: 3px;
        margin-bottom: 2rem;

        span {
            display: none;
        }
    }

    .input-container {
        input, select {
            border: 1px solid #e5e5e5;
            padding: .5rem;
        }
        textarea {
            width: 100%;
        }
    }

    .form-container {
        table, tr, td{
            display: block;
            width: auto !important;
        }
        td {
            margin-top: .5rem;
        }
        label {
            font-size: 0.8235rem;
            margin: 0;
            font-weight: bold;
            color: #707070;
            font-family: 'Segoe UI';
        }
    }

}