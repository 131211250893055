.breadcrumbs {
    ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        margin: 0.5rem 0;

        li {
            margin-right: 4px;
            color: $color-primary;

            a {
                font-size: .9444rem;
                color: $color-font-heading;
                &.last {
                    color: $color-font-default;
                    color: #9b9b9b;
                }
                &:hover {
                    color: $color-primary;
                }
            }
        }
    }
}