.footer-container {
    flex-direction: column-reverse;
    color: $color-gray;
}

div.menu-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px 0;

    div.menu-items {
        margin-bottom: 30px;
        padding: 10px;
        padding-right: 30px;

        a {
            color: $color-white;
            font-size: 14px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                font-size: 18px;
                padding: .2rem 0;
            }
        }

        h3 {
            a {
                font-size: 1.125rem;
            }
            font-weight: 700;
            padding-bottom: 1rem;
        }
    }
}

div.contact-container {

    margin: 30px 0;

    .svg-icon-hldisplay-logo {
        fill: #fff;
    }

    img.footer-logo {
        max-width: 100%;
        width: auto;
        height: auto;
        margin-bottom: 30px;
        align-self: center
    }


    div.social-icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        img {
            padding: 5px;
            width: auto;
            height: 100%;
        }
    }

}

p.copyright {
    color: $color-gray;
    font-size: 1rem;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

@include media-breakpoint-up(md) {
    
    .footer-container {
        flex-direction: row;    

        div.menu-container {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            div.menu-items {
                margin-right: 20px;
        
                h3 {
                    font-size: 1.5rem;
                    font-weight: 700;
                    color: #fff;
                }

            }

        }

        div.contact-container {
            
            img.footer-logo {
                max-width: 100%;
                height: auto;
                margin: 0 auto;
                margin-bottom: 30px;
                align-self: center            
            }

            div.social-icons {
                justify-content: center;
            }
            
        }

    }

}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {

    .footer-container {
        flex-direction: column-reverse;

        div.menu-container {
            flex-direction: row;
            margin-top: 0;
        }

        div.contact-container {
            flex-direction: row;
           
                       
            img.footer-logo {
                margin: 0;
                margin-bottom: 30px;
            }

            div.social-icons {
                justify-content: flex-start;
            }
        }
    }
}

