.software-item-container{
    flex: 48%;
    border-radius: 3px;
    // box-shadow: 0 6px 8px 0 rgba(164,164,164,0.21);
    margin: 1%;
    background-color: $color-white;
    // flex-direction: column;

    @include media-breakpoint-down(md) {
        flex: 96%;
        margin-bottom: 1rem;
    }

    div.description-container {
        padding: 2rem;
        height: 15rem;
        display: flex;
        flex-direction: row;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            height: auto;
        }

        div.software-image {
            width: 15%;
            padding-right: 30px;

            @include media-breakpoint-down(md) {
                margin-bottom: 30px;
                padding-right: 0px;
            }

        }

        div.description {
            width: 85%;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        .arrow_link {
            margin-bottom: 1rem;
            font-size: 1.5rem;
            
            a {
                font-family: "Source Sans Pro";
                font-weight: normal;
                font-weight: 500;
                color: $color-font-heading;

            }
        }

    }

}