.sidebar .nav-parent {
    padding: 0 .4rem .4rem 0;
    
    a {
        display: block;
        font-size: 1.445rem;
        position: relative;
        color: $color-font-default;
        padding-left: 2rem;
    
    
        &:before {
            content: '\279C';
            color: $color-primary;
            display: inline-block;
            position: absolute;
            font-size: 1.2rem;
            line-height: 1.5em;
            left: 0px;
            width: 28px;
            transform: rotate(180deg);
        }
    
        &:hover {
            color: $color-primary;
        }
    }
}

.sidebar .nav {
    list-style-type: none;
    padding: 0;

    border: 1px solid #f5f5f5;
    border-bottom: none;
    background: #f5f5f5;

    .direct-children-menu > a {
            font-size: 0.83333rem;
    }

    a {
        display: block;
        color: $color-font-sidebar-nav;
        padding: .75rem 1rem;

        &:hover {
            background-color: #f5f5f5;
            text-decoration: none;
        }
    }
    
    li {
        background-color: $color-white; 
        border-left: 10px solid #f5f5f5;
        border-bottom: 1px solid #f5f5f5;

        & > ul {
            padding:0;
        }
    }
    
    ul {
        list-style-type: none;

        & > li a {
            font-size: 0.83333rem;
        }

        & > li:first-child {
            border-top: 1px solid #f5f5f5;
        }
        & > li:last-child {
            border-bottom: none;
        }
    }

    .nav-selected > a {
        border-right: 4px solid $color-primary;
        background-color: #f5f5f5;
    }

    li.direct-children-menu {
        a {
            border-left: 10px solid #f5f5f5;
        }
        
    }


}