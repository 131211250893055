/* div.full-bleed {
    // background: url('https://placeimg.com/640/480/nature');
    background-size: cover;
    background-position: center center;
    height: 40vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .hero {

        p {
            color: #ffffff;
            font-size: 42px;
        }
        
    }
} */

#bg {
    width: 100vw;
    padding: 0;
    height: 40vh;
    object-fit: cover;
}

#full {
    max-width: 100vw;
    padding: 0;
}

#center {
    margin: 0 auto;
    display: block;
}

#max {
    width: 100%;
    height: auto;
}
