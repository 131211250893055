.textlinkblock {
    text-align: center;
    color: $color-white;

    h2 {
        color: $color-white;
    }

    .text {
        color: $color-white;
        font-weight: 400;
        font-size: 20px;
    }
    
    .button {
        display: inline-block;
        text-transform: uppercase;
        color: $color-dark;
        font-weight: 600;
        font-size: .8rem;
        margin-top: 1.5rem;
    }
    .button:hover {
        color: $color-dark;
        text-decoration: underline;
    }
}