.product-page-list {
    display: flex;
    flex-flow: wrap;

    .heading {
        display: block;
        width: 100%;
        text-align: center;
        padding: 1rem 0 .5rem;
        color: $color-primary;
    }

    .product-item {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 48%;
        padding: 1.25rem;
        border-radius: 3px;
        margin: 1%;
        background-color: $color-white;
        height: auto;

        @include media-breakpoint-down(md) {
            width: 96%;
            margin-bottom: 1rem;
            flex-direction: column;
        }
    }

    .product-image-container {
        width: 28%;
        flex-basis: 28%;
        
        @include media-breakpoint-down(md) {
            width: 50%;
            flex-basis: 50%;
        }
    }

    .product-image {
        width: 100%;
        padding-right: 16%;

        @include media-breakpoint-down(md) {
            padding: 8% 0;
        }
        
    }

    .product-info {
        width: 100%;
    }

    .product-title {
        margin-bottom: .75rem;
        font-size: 1.5rem;
        color: $color-font-heading;
        
        .title-part {
            color: $color-primary;
        }

        a {
            color: $color-font-heading;
        }
    }

    .product-description {
        &:last-child {
            margin-bottom: 0;
        }
    }

}