.login-container, .forgot-container {
    background: #f5f5f5;
    padding: 2rem 1rem;
    margin-bottom: 2rem;
}

.login-input {
    width: 100%;
    height: 49px;
    padding-left: 1.5em;
    padding-top: 16px;
    padding-bottom: 14px;
    border-radius: 3px;
    border: 1px solid #e5e5e5;
    color: #444;
    background-color: #FFFFFF;
}

.login-button {
    margin-top: 1em;
    display: inline-block;
    background-color: #d63740;
    color: #fff;
    border-radius: 4px;
    font-size: 1.2rem;
    padding: .75rem 1.5rem;
}