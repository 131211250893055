.resource-container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    align-items: center;

    .download-icon {
        padding-right: 20px;
        
        svg {
            width: 48px;
            height: 48px;
        }
    }

    .resource-content {
        
        a {
            color: #000;
            font-weight: 700;
        }

        p {
            margin: 0;
        }

    }
}

hr.resource-divider {
    margin-bottom: 30px;
    padding: 0;
    border: 1px solid #ececec;
}