p {
    color: #777777;
}

// Gray container template with rounded
// top-right, bottom-left corners
.gray-container {
    background-color: #FAFAFA;
    border: 1px solid #EBEBEB;
	border-radius: 2px 15px 2px 15px;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px; // Temp

    a {
        color: #3993C3;
        text-decoration: underline;
    }

}

.beige-container {
    background-color: #f7f1b7;
    border: 1px solid #e6da67;
	border-radius: 2px 15px 2px 15px;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px; // Temp

    a {
        color: #3993C3;
        text-decoration: underline;
    }    
}

.mob-content {
    display: block;
    @include media-breakpoint-up(md) {
        display: none;
    }            
}

.desktop-content {
    display: none;
    @include media-breakpoint-up(md) {
        display: block;
    }     
}