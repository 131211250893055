.main-menu {
    width: 70%;
    
    ul {
        margin: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        li {
            margin-right: 5%;
        }

        a {
            color: $color-font-heading;

            &:hover {
                color: $color-primary;    
            }
        }
    }

    @include media-breakpoint-down(md) {
        display: none;
    }

}

/*
@include media-breakpoint-down(md) {
    
    .main-menu, #header_searchfield {
        display: none;
    }

}
*/

// --


// $first-level-height: 2.5rem;
// $second-level-height: 1.75rem;
// $third-level-height: 1.5rem;


// .no-scroll-body {
//     overflow: hidden;
// }

// .close-mobile-menu,
// .open-mobile-menu {
//     color: #ffffff;
// }

// .main-menu {
//     color: $white;
//     position: fixed;
//     left: 100vw;
//     top: 0;
//     width: 100vw;
//     height: 100vh;
//     background-color: $color-darkgrayer;
//     overflow: scroll;
//     transition: left .25s cubic-bezier(.07,.95,0,1);
    
//     &.open {
//         left: 0;
//         z-index: 1000;
//     }
    
//     &__link {
//         color: $white;
//         display: inline-block;
//     }
// }

// .level-item {
//     overflow: hidden;
//     position: relative;
//     transition: max-height 0.25s cubic-bezier(.07,.95,0,1);
    
//     &.first-level__item {
//         max-height: $first-level-height;
//     }

//     &.second-level__item {
//         max-height: $second-level-height;
//     }

//     &.third-level__item {
//         max-height: $third-level-height;
//     }

//     &.item-open {
//         max-height: 100rem;
//     }
// }

// .first-level {
//     &__item {
//         margin: 1rem 0;
        
//         &:last-of-type {
//             margin: 0;
//         }
//     }
//     &__link {
//         line-height: $first-level-height;
//         font-size: $first-level-height - 0.5;
//     }
// }

// .second-level {
//     &__item {
//         margin: 1rem 0;

//         &:last-of-type {
//             margin: 0;
//         }
//     }
//     &__link {
//         line-height: $second-level-height;
//         font-size: $second-level-height - 0.5;
//     }
// }

// .third-level {
//     &__item {
//         margin: 1rem 0;

//         &:last-of-type {
//             margin: 0;
//         }
//     }
//     &__link {
//         line-height: $third-level-height;
//         font-size: $third-level-height - 0.5;
//     }
// }

// .expand-children {
//     display: inline-block;
//     height: $first-level-height;
//     line-height: $first-level-height;
//     vertical-align: top;
//     position: absolute;
//     right: 0;
//     cursor: pointer;
// }

// @include media-breakpoint-up(sm) {
// }

// @include media-breakpoint-up(md) {

// }

// @include media-breakpoint-up(lg) {

// }

// @include media-breakpoint-up(xl) {
//     .main-menu {
//         position: static;
//         width: auto;
//         height: auto;
//         overflow: hidden;
//         background-color: transparent;
//     }

//     .level-item {
//         &.first-level__item {
//             max-height: $first-level-height;
//         }
//     }
    
//     .first-level {
//         display: flex;
//         flex-flow: row;
        
//         &__item {
//             padding: 0.5rem;
//             margin: 0 1rem;
//             font-size: 1.2rem;
            
//             &:first-of-type {
//                 margin-left: 0;
//             }
//         }

//         &__link {
//             font-size: 1rem;
//             font-weight: 600;
//             line-height: 1rem;
//             color: $color-text-primary;

//             &.active {
//                 color: $primary;
//             }
//         }
//     }
    
//     .second-level {
//         display: none;
//         &.open {
//             display: block;
//             background-color: #fff;
//             position: fixed;
//             height: 50vh;
//             width: 100vw;
//             left: 0;
//             right: 0;
//         }
        
//         &__link {
//             color: $color-text-primary;
//         }
//     }


// }