﻿@import "vendor/bootstrap-v4.1.1/functions";

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


//
// Color system
//

$color-green: rgba(0, 150, 94, 1);
$color-green-2: rgba(0, 128, 80, 1);

$color-blue: rgba(0, 145, 210, 1);
$color-blue-2: rgba(0, 115, 152, 1);
$color-light-blue: rgba(229, 241, 244, 1);
$color-cream-white: rgba(246, 246,247,1);
$color-red: rgba(224, 0, 77, 1);
$color-pink: rgba(230, 51, 113, 1);
$color-pink-2: rgba(253, 237, 243, 1);
$color-yellow: rgba(255,200,69, 1);

// stylelint-disable
$color-white: rgb(255, 255, 255);
$color-gray-100: rgb(252, 252, 252);
$color-gray-200: rgb(249, 249, 249);
$color-gray-300: rgb(242, 242, 242);
$color-gray-400: rgb(229, 229, 229);
$color-gray-500: rgb(204, 204, 204);
$color-gray-600: rgb(127, 127, 127);
$color-gray-700: rgb(68, 68, 68);
$color-gray-800: rgb(51, 51, 51);
$color-gray-900: rgb(25, 25, 25);
$color-black: rgb(0, 0, 0);

$color-text-primary: rgb(102, 102, 102);
$color-text-secondary: $color-gray-700;

$grays: () !default;
$grays: map-merge(( "gray-100": $color-gray-100, "gray-200": $color-gray-200, "gray-300": $color-gray-300, "gray-400": $color-gray-400, "gray-500": $color-gray-500, "gray-600": $color-gray-600, "gray-700": $color-gray-700, "gray-800": $color-gray-800, "gray-900": $color-gray-900 ), $grays);

$primary: $color-blue;
$secondary: $color-gray-600;
$success: $color-green;
$info: $color-light-blue;
$warning: $color-yellow;
$danger: $color-red;
$light: $color-gray-100;
$dark: $color-gray-800;

$color-list: (
    primary-color: $primary,
    white: #FFF
);

$theme-colors: ();
// $theme-colors: map-merge((
//   "primary":    $primary,
//   "secondary":  $secondary,
//   "success":    $success,
//   "info":       $info,
//   "warning":    $warning,
//   "danger":     $danger,
//   "light":      $light,
//   "dark":       $dark
// ), $theme-colors);
// stylelint-enable

$text-colors: map-merge(( "primary": $color-text-primary, "secondary": $color-text-secondary, "white": $color-white,
// "green":        $color-green,
// "green-2":      $color-green-2,
"blue": $color-blue, "blue-2": $color-blue-2,
// "blue-3":       $color-blue-3,
// "cream-white":  $color-cream-white,
// "color-red":    $color-red,
// "color-pink":   $color-pink,
// "color-pink-2": $color-pink-2,
// "color-yellow": $color-yellow,
), $grays );

$links: ( blue: ( color: $color-blue, hover: $color-blue-2, text-decoration: underline ), green: ( color: $color-green, hover: $color-green, text-decoration: underline ));

$bg-colors: ( "primary": $primary, "secondary": $secondary, green: $color-green, info: $info, error: $color-pink-2 );

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $color-gray-900 !default;
$yiq-text-light: $color-white !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true !default;
$enable-print-styles: true !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(( 0: 0, 1: ($spacer * .25), 2: ($spacer * .5), 3: $spacer, 4: ($spacer * 1.5), 5: ($spacer * 3) ), $spacers);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map-merge(( 25: 25%, 50: 50%, 75: 75%, 100: 100%, auto: auto ), $sizes);
// stylelint-enable

// Body
//
// Settings for the `<body>` element.

$body-bg: $color-white;
$body-color: $color-gray-900;

// Links
//
// Style anchor elements.

// $link-color:                theme-color();
// $link-decoration:           none !default;
// $link-hover-color:          darken($link-color, 15%) !default;
// $link-hover-decoration:     underline !default;

$link-color: $color-blue;
$link-decoration: none;
$link-hover-color: currentColor;
$link-hover-decoration: underline;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1300px );

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px, xxl: 1300px );

$max-width-container: nth($container-max-widths, length($container-max-widths));

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 30px;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5;
$line-height-sm: 1.5;

$border-width: 1px;
$border-color: $color-gray-300;

$border-radius: 1rem;
$border-radius-lg: .3rem;
$border-radius-sm: .2rem;

$box-shadow-sm: 0 .125rem .25rem rgba($color-black, .075);
$box-shadow: 0 .5rem 1rem rgba($color-black, .15);
$box-shadow-lg: 0 1rem 3rem rgba($color-black, .175);

$component-active-color: $color-white;
$component-active-bg: theme-color("primary");

$caret-width: .3em;

$transition-base: all .2s ease-in-out;
$transition-fade: opacity .15s linear;
$transition-collapse: height .35s ease;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-base: "Open Sans", Arial,sans-serif;
$headings-font-family: "Open Sans", Arial,sans-serif;

$font-size-base: 1rem;
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * .875) !default;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;


// Form, inputs

$input-border-color: rgba(0, 0, 0, .2);
$input-border-selected-color: $color-blue-2;
$input-border-error-color: $color-red;
$input-bg-disabled-color: $color-cream-white;


// Border colors

$color-border: $color-gray-400;

